import Chart from 'chart.js/auto';

function graph() {
    const pendingChart = document.getElementById('pendingChart');
    if (pendingChart) {


        var formData = new FormData();
        formData.append("_token", $('meta[name="csrf-token"]').attr("content"));
        formData.append("status", 'pending');

console.log(formData)
        $.ajax({
            url: "/project-endpoint", // Custom endpoint
            type: "POST",
            data: formData,
            processData: false, // Nécessaire pour envoyer des fichiers
            contentType: false, // Nécessaire pour envoyer des fichiers
        }).done(function (result) {
              console.log(result)
            let chart = new Chart(pendingChart, {
                type: 'doughnut',
                data: result,
                options: {
                    "plugins": {
                        "legend": {
                            "display": true,
                            "position": "bottom",
                            "align": "start"
                        }
                    }
                }
            });
        });
    }
    const finishedChart = document.getElementById('finishedChart');
    if (finishedChart) {


        var formData = new FormData();
        formData.append("_token", $('meta[name="csrf-token"]').attr("content"));
        formData.append("status", 'finished');


        $.ajax({
            url: "/project-endpoint", // Custom endpoint
            type: "POST",
            data: formData,
            processData: false, // Nécessaire pour envoyer des fichiers
            contentType: false, // Nécessaire pour envoyer des fichiers
        }).done(function (result) {
           
            let chart = new Chart(finishedChart, {
                type: 'doughnut',
                data: result,
                options: {
                    "plugins": {
                        "legend": {
                            "display": true,
                            "position": "bottom",
                            "align": "start"
                        }
                    }
                }
            });
        });
    }

}
export {
    graph
}