import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function editor() {
  const editors = document.querySelectorAll(".editor");
  editors.forEach((element) => {
    ClassicEditor.create(element, {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
      ],
      link: {
        decorators: {
          openInNewTab: {
            mode: "manual",
            label: "Open in a new tab",
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
        },
      },
    }).catch((error) => {
      console.error(error);
    });
  });
  const single = document.querySelectorAll(".editor-single");
  single.forEach((element) => {
    ClassicEditor.create(element, {
      toolbar: [

      ],

    }).catch((error) => {
      console.error(error);
    });
  });
}
export { editor };
