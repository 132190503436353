import 'parsleyjs/dist/parsley.min.js'

function validate() {



    Parsley.addMessages('fr', {
        defaultMessage: "Cette valeur semble non valide.",
        type: {
            email: "Cette valeur n'est pas une adresse email valide.",
            url: "Cette valeur n'est pas une URL valide.",
            number: "Cette valeur doit être un nombre.",
            integer: "Cette valeur doit être un entier.",
            digits: "Cette valeur doit être numérique.",
            alphanum: "Cette valeur doit être alphanumérique."
        },
        notblank: "Cette valeur ne peut pas être vide.",
        required: "Ce champ est requis.",
        pattern: "Cette valeur semble non valide.",
        min: "Cette valeur ne doit pas être inférieure à %s.",
        max: "Cette valeur ne doit pas excéder %s.",
        range: "Cette valeur doit être comprise entre %s et %s.",
        minlength: "Cette chaîne est trop courte. Elle doit avoir au minimum %s caractères.",
        maxlength: "Cette chaîne est trop longue. Elle doit avoir au maximum %s caractères.",
        length: "Cette valeur doit contenir entre %s et %s caractères.",
        mincheck: "Vous devez sélectionner au moins %s choix.",
        maxcheck: "Vous devez sélectionner %s choix maximum.",
        check: "Vous devez sélectionner entre %s et %s choix.",
        equalto: "Cette valeur devrait être identique."
    });

    Parsley.setLocale('fr');
    var parsleys = $(document).find('.form-validate');
    parsleys.each(function (index, el) {
        let valid = $(el).parsley();

    })

    window.Parsley.addValidator('maxFileSize', {
        validateString: function (_value, maxSize, parsleyInstance) {
            if (!window.FormData) {
                alert('You are making all developpers in the world cringe. Upgrade your browser!');
                return true;
            }
            var files = parsleyInstance.$element[0].files;

            return 1 != files.length || files[0].size <= maxSize * 1024;
        },
        requirementType: 'integer',
        messages: {

            en: 'This file should not be larger than %s Kb',
            fr: 'Ce fichier est trop lourd.'
        }
    });

}
export {
    validate
}