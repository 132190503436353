import {
    project
} from "./project";
import {
    select
} from "./select";
import {
    editor
} from "./editor";
import {
    validate
} from "./formvalidate";
import {
    graph
} from "./chart";
import {
    slider
} from "./slider";
project()
select()
editor()
validate()
graph();
slider();

$( document ).ready(function() {
    setTimeout(() => {
        
        $(document).find('.loading-overlay').fadeOut();
    }, 1000);
});