function project() {
    // Unbind existing event handlers to avoid multiple bindings
    $(document)
        .off("submit", "#edit_project")
        .on("submit", "#edit_project", function (e) {
            e.preventDefault();
            let $form = $(this);
            edit($form);
        });
    $(document).on("change", ".project_tools_input", function () {
        // Récupérer le nom du fichier
        var fileName = $(this).val().split("\\").pop();

        $(this).next().val(fileName);
    });
    $(document).on("click", ".add_file", function () {
        let $html = '<tr class="file_uploader"><td><input type="file" class="project_tools_input show" name="project_tools[]" id="project_tools"><input type="hidden" class="filename"   name="project_tools_filename[]" id="project_tools" value=""></td><td></td><td></td><td><svg class="delete-line icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.3445 4.01721C10.4136 3.67419 13.5864 3.67419 16.6555 4.01721C18.3682 4.20863 19.7499 5.55804 19.9511 7.27753C20.318 10.4152 20.318 13.5849 19.9511 16.7225C19.7499 18.442 18.3682 19.7914 16.6555 19.9828C13.5864 20.3258 10.4136 20.3258 7.3445 19.9828C5.63178 19.7914 4.25002 18.442 4.04891 16.7225C3.68194 13.5849 3.68194 10.4152 4.04891 7.27753C4.25002 5.55804 5.63178 4.20863 7.3445 4.01721ZM8.46967 8.46967C8.76256 8.17678 9.23744 8.17678 9.53033 8.46967L12 10.9393L14.4697 8.46968C14.7626 8.17679 15.2374 8.17679 15.5303 8.46968C15.8232 8.76257 15.8232 9.23745 15.5303 9.53034L13.0607 12L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7626 15.8232 14.4697 15.5303L12 13.0607L9.53034 15.5303C9.23744 15.8232 8.76257 15.8232 8.46968 15.5303C8.17678 15.2374 8.17678 14.7626 8.46968 14.4697L10.9393 12L8.46967 9.53033C8.17678 9.23744 8.17678 8.76256 8.46967 8.46967Z" fill="#1E1E1E"/></svg></td></tr>'
        $(this).parents('table').find('tbody').append($html)
    })
    $(document).on("click", ".delete-line", function () {
        $(this).parents('.file_uploader').remove();
    })
    $(document).on("click", ".edit_file", function () {
        // Récupérer le nom du fichier

        $(this).toggleClass("active");
        let $fileinput = $(this)
            .parents(".file_uploader")
            .find('input[type="file"]');
        let $filename = $(this).parents(".file_uploader").find(".filename");

        if ($(this).hasClass("active")) {
            $(this).find(".edit").addClass("hide");
            $(this).find(".cancel").removeClass("hide");
            $fileinput.prop("disabled", false).addClass("show");
            $filename.removeClass("show");
        } else {
            $(this).find(".edit").removeClass("hide");
            $(this).find(".cancel").addClass("hide");

            $fileinput.prop("disabled", true).removeClass("show");
            $filename.addClass("show");
        }
    });
}

function uploader() {}

function edit($form) {
    // Extract form data as key-value pairs
    var formData = new FormData();

    // Récupérez tous les champs du formulaire (sauf les fichiers) avec serializeArray
    var formFields = $form.serializeArray();

    // Ajoutez les champs au FormData
    $.each(formFields, function (index, field) {
        formData.append(field.name, field.value);
    });

    // Ajoutez manuellement les fichiers au FormData
    $.each($form.find('input[type="file"]'), function (index, fileInput) {
        var files = fileInput.files;
        var fieldName = $(fileInput).attr("name");

        // Ajoutez chaque fichier au FormData avec le même nom de champ
        $.each(files, function (i, file) {
            formData.append(fieldName, file);
        });
    });

    // Ajoutez d'autres champs manuellement si nécessaire
    formData.append("_token", $('meta[name="csrf-token"]').attr("content"));
    formData.append("action", 'project_crud')
    let result = confirm("Etes-vous sur de vouloir éditer ce projet");
    if (result) {

        $.ajax({
            url: themosis.ajaxurl, // Custom endpoint
            type: "POST",
            data: formData,
            contentType: false, // Nécessaire pour envoyer des fichiers
            processData: false, // Nécessaire pour envoyer des fichiers
            success: function (response) {
                // Votre logique de gestion du succès ici
                // Affiche le message du serveur
                window.location.href = response.data.redirect;

            },
            error: function (error) {
                // Votre logique de gestion des erreurs ici
                console.error('Erreur AJAX:', error);
            }
        })
    }
}

export {
    project
};