import Swiper, {
    Navigation,
    Pagination
} from "swiper";
// import Swiper styles
import "swiper/swiper.min.css";


var swiperPost;

function slider() {

    // Sélectionnez tous les éléments avec la classe ".swiper"
    const swiperElements = document.querySelectorAll(".swiper");
    var productlist = $(document).find('.swiper').each(function (i, el) {
        let next = $(el).find('.swiper-button-next');
        let prev = $(el).find('.swiper-button-prev');

        new Swiper($(el).get(0), {
            modules: [Navigation],

            // Ajoutez les options Swiper selon vos besoins

            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 16
                },
                // when window width is >= 480px

                // when window width is >= 768px
                768: {
                    slidesPerView: 'auto',
                    spaceBetween: 24,
                }
            },
            // Ajoutez d'autres options au besoin
            navigation: {
                nextEl: next.get(0),
                prevEl: prev.get(0),
            },

        });
    });


}
export {
    slider
};